exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-diseqc-index-js": () => import("./../../../src/pages/products/diseqc/index.js" /* webpackChunkName: "component---src-pages-products-diseqc-index-js" */),
  "component---src-pages-products-dvb-index-js": () => import("./../../../src/pages/products/dvb/index.js" /* webpackChunkName: "component---src-pages-products-dvb-index-js" */),
  "component---src-pages-products-lnb-index-js": () => import("./../../../src/pages/products/lnb/index.js" /* webpackChunkName: "component---src-pages-products-lnb-index-js" */),
  "component---src-pages-products-misc-index-js": () => import("./../../../src/pages/products/misc/index.js" /* webpackChunkName: "component---src-pages-products-misc-index-js" */),
  "component---src-pages-products-mounts-index-js": () => import("./../../../src/pages/products/mounts/index.js" /* webpackChunkName: "component---src-pages-products-mounts-index-js" */),
  "component---src-templates-diseqc-product-details-js": () => import("./../../../src/templates/diseqc-product-details.js" /* webpackChunkName: "component---src-templates-diseqc-product-details-js" */),
  "component---src-templates-dvb-product-details-js": () => import("./../../../src/templates/dvb-product-details.js" /* webpackChunkName: "component---src-templates-dvb-product-details-js" */),
  "component---src-templates-lnb-product-details-js": () => import("./../../../src/templates/lnb-product-details.js" /* webpackChunkName: "component---src-templates-lnb-product-details-js" */),
  "component---src-templates-misc-product-details-js": () => import("./../../../src/templates/misc-product-details.js" /* webpackChunkName: "component---src-templates-misc-product-details-js" */),
  "component---src-templates-mounts-product-details-js": () => import("./../../../src/templates/mounts-product-details.js" /* webpackChunkName: "component---src-templates-mounts-product-details-js" */),
  "component---src-templates-recommended-product-details-js": () => import("./../../../src/templates/recommended-product-details.js" /* webpackChunkName: "component---src-templates-recommended-product-details-js" */)
}

